<template>
  <div class="app-container speciality">
    <type-seach @ok="SearchOccupationlist"></type-seach>
    <h3 class="title">搜索：{{ typecode }}型</h3>
    <div v-for="(item, index) in pagelist" :key="index" class="mainbox">
      <div class="titlebox" @click="godetail(item)">
        <div>
          {{ item.ProfessionName }}
          <i v-if="item.HasVideo" class="el-icon-caret-right iconbox"></i>
        </div>
        <!-- <div class="codebox">兴趣代码：{{item.InterestCode}}</div> -->
      </div>
      <div class="content">{{ item.ProfessionDesc }}</div>
    </div>
    <div v-if="total > PageSize" class="el-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="PageSize"
        :current-page="PageIndex"
        :total="total"
        @current-change="PageIndexChange"
      ></el-pagination>
    </div>
    <div v-if="total <= 0" class="defult">没有搜索到对应的职业</div>
  </div>
</template>

<script>
import API from '@/api/config'
// import { ProfessionSearchByInterestCode } from "@/api/career/profession";
import typeSeach from "@/views/tool/profession/local-components/SearchProfessionType";
export default {
  components: {
    typeSeach,
  },
  data() {
    return {
      Name: "",
      pagelist: null,
      PageIndex: 1,
      PageSize: 10,
      total: 0,
    };
  },
  watch: {
    $route: function (to, from) {
      // 执行数据更新查询
      this.init();
    },
  },
  created() {
    this.init();
  },

  methods: {
    init() {
      if (this.$route.query.typecode) {
        this.typecode = this.$route.query.typecode;
        this.getdata();
      }
    },
    PageIndexChange(val) {
      this.PageIndex = val;
      this.getdata();
    },
    SearchOccupationlist(val) {
      this.PageIndex = 1;
      this.typecode = val;
      this.getdata();
    },
    getdata() {
      API.Profession.ProfessionSearchByInterestCode({
        InterestCode: this.typecode,
        PageIndex: this.PageIndex,
        PageSize: this.PageSize,
      }).then((res) => {
        this.pagelist = res.data.Data;
        this.total = res.data.Total;
      });
    },
    godetail(val) {
      this.$router.push({
        path: "/profession-detail",
        query: { ID: val.ID },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.speciality {
  padding-bottom: 2rem;
  width: 66%;
  .title {
    color: rgba(var(--themecolor),1);
  }
  h4 {
    cursor: pointer;
  }
}
.content {
  white-space: pre-wrap;
  line-height: 1.5;
  color: #999;
}
.el-pagination {
  text-align: center;
  padding: 20px 0;
}
.mainbox {
  padding: 20px;
  &:hover {
    background: #f9f9f9;
  }
  .titlebox {
    display: flex;
    align-items: center;
    height: 60px;
    // background: #f9f9f9;
    cursor: pointer;
    line-height: 1.5;
    font-size: 20px;
    margin-bottom: 10px;
    &:hover {
      color: rgba(var(--themecolor),1);
    }
    .iconbox {
      border: 1px solid rgba(var(--themecolor),0.75);
      color: rgba(var(--themecolor),0.75);
      border-radius: 4px;
      margin-left: 6px;
    }
    .codebox {
      margin-left: 100px;
    }
  }
}
.defult {
  font-size: 24px;
  color: #999;
  margin: 50px;
}
</style>
<style lang="less">
.app-container .el-button--primary,.app-container .el-button--primary:focus{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.app-container .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
.app-container .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.app-container .el-select .el-input__inner:focus,.app-container .el-input__inner:focus{
  border-color: rgba(var(--themecolor),0.75);
}
.el-select-dropdown__item.selected{
  color: rgba(var(--themecolor),0.75);
}
.app-container .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: rgba(var(--themecolor),0.75);
}
.app-container .el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.2);
}
.app-container .el-pagination.is-background .el-pager li:not(.disabled).active:hover{
  color: #FFF;
  background-color: rgba(var(--themecolor),0.75);
}
.app-container .el-pagination.is-background .btn-next:hover, .app-container .el-pagination.is-background .btn-prev:hover, .app-container .el-pagination.is-background .el-pager li:hover{
  background-color: rgba(var(--themecolor),0.2);
}
</style>